let option = {
  title: {
    left: 'center',
    text: '',
    textStyle: {
      fontSize: 16
    }
  },
  grid: {
    top: 54,
    left: 42,
    right: 42,
    bottom: 40
  },
  legend: {
    data: ['分数', '比率'],
    // top: 10,
    right: 50,
    itemWidth: 30,
    itemHeight: 16,
    itemGap: 40,
    textStyle: {
      fontSize: '16px'
    }
  },
  xAxis: {
    type: 'category',
    axisLine: {
      show: true,
      lineStyle: {
        color: '#D9D9D9'
      }
    },
    axisLabel: {
      color: '#888888',
      fontSize: '16px',
      overflow: 'break'
    },
    data: []
  },
  yAxis: [
    {
      type: 'value',
      axisLine: {
        show: true,
        lineStyle: {
          color: '#D9D9D9'
        }
      },
      axisTick: {
        show: true
      },
      axisLabel: {
        color: '#888888',
        fontSize: '16px',
        padding: [0, 13, 0, 0]
      },
      splitLine: {
        show: false
      }
    },
    {
      type: 'value',
      max: 1,
      axisLine: {
        show: true,
        lineStyle: {
          color: '#D9D9D9'
        }
      },
      axisTick: {
        show: true
      },
      axisLabel: {
        color: '#888888',
        fontSize: '16px'
      },
      splitLine: {
        show: false
      }
    }
  ],
  series: [
    {
      name: '人数',
      data: [],
      type: 'bar',
      color: '#FFBB00',
      barWidth: '40px',
      itemStyle: {
        borderRadius: 3
      }
    },
    {
      name: '比率',
      yAxisIndex: 1,
      data: [],
      type: 'line',
      smooth: true,
      color: '#448BFF'
    }
  ]
}

export default { option }
