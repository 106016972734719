let option = {
  series: [
    {
      type: 'pie',
      center: ['50%', '137'],
      radius: ['78', '107'],
      avoidLabelOverlap: false,
      label: {
        show: true,
        formatter: '{b}:{c}',
        width: 300,
        overflow: 'break',
        fontSize: '24px'
      },
      labelLine: {
        show: true,
        length2: 80
      },
      data: []
    }
  ]
}
export default { option }
