//水平两根的柱状图
let option = {
  legend: {
    left: 361,
    show: true,
    itemGap: 45,
    itemHeight: 16,
    textStyle: {
      fontSize: '16px',
      padding: [0, 0, 0, 5]
    }
  },
  grid: {
    top: 38,
    left: 199,
    right: 123,
    bottom: 20
  },
  xAxis: {
    type: 'value',
    max: 100,
    splitNumber: 10,
    axisLine: {
      show: true,
      lineStyle: {
        color: '#D9D9D9'
      }
    },
    axisLabel: {
      show: false
    }
  },
  yAxis: {
    name: '技能',
    nameTextStyle: {
      color: '#000',
      fontSize: '16px',
      fontWeight: 'bold',
      align: 'right',
      padding: [0, 18, 0, 0]
    },
    type: 'category',
    data: [],
    axisLabel: {
      width: 120,
      overflow: 'break',
      color: '#888888',
      fontSize: '16px',
      padding: [0, 24, 0, 0]
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#D9D9D9'
      }
    }
  },
  series: [
    {
      name: '平均掌握率',
      type: 'bar',
      data: [],
      label: {
        show: true,
        formatter: '{c}%',
        position: 'right'
      },
      color: '#7CAEEF',
      barWidth: 16
    }
  ]
}

export default { option }
