let option = {
  legend: {
    data: ['得分', '失分'],
    itemWidth: 30,
    itemHeight: 16,
    itemGap: 45,
    textStyle: {
      fontSize: '16px',
      padding: [0, 0, 0, 5]
    }
  },
  grid: {
    top: 37,
    left: 123,
    right: 119,
    bottom: 27
  },
  xAxis: {
    type: 'category',
    data: [],
    axisLabel: {
      interval: 0,
      color: '#888888',
      fontSize: '16px',
      width: 150,
      overflow: 'break'
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#D9D9D9'
      }
    }
  },
  yAxis: {
    type: 'value',
    axisLine: {
      show: true,
      lineStyle: {
        color: '#D9D9D9'
      }
    },
    axisLabel: {
      color: '#888888',
      fontSize: '16px',
      padding: [0, 6, 0, 0]
    },
    axisTick: {
      show: true
    },
    splitLine: {
      show: false
    }
  },
  series: [
    {
      name: '得分',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        color: '#FFFFFF',
        fontSize: '16px'
      },
      barWidth: 40,
      itemStyle: {
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius: [0, 0, 3, 3]
        }
      },
      color: '#6BA7F5',
      emphasis: {
        focus: 'series'
      },
      data: []
    },
    {
      name: '失分',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        color: '#FFFFFF',
        fontSize: '16px'
      },
      barWidth: 40,
      itemStyle: {
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius: [3, 3, 0, 0]
        }
      },
      color: '#FF6060',
      emphasis: {
        focus: 'series'
      },
      data: []
    }
  ]
}
export default { option }
