import config from '../config/config'
import { axios } from './index'

const enterEvaluateReportUrl = config.baseUrl + '/exam/api/v1/enter/report'

export default {
  //查看随机出卷个人考试报告
  getPersonalReport(payload) {
    return axios.post(`${enterEvaluateReportUrl}/random/personal?interval=10`, payload)
  },
  //excel报告
  downloadOverallReport(examId) {
    return axios.get(`${enterEvaluateReportUrl}/download/excel/skill/score?examId=${examId}`, {
      responseType: 'blob'
    })
  }
}
