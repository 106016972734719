<template>
  <el-dialog
    class="personal-report-dialog"
    v-loading="loading"
    :visible.sync="visible"
    top="60px"
    :before-close="close"
    @closed="closed"
    destroy-on-close
  >
    <div class="print-button back-top" @click="backTop">回到顶部</div>
    <div
      class="print-button"
      @click="pdfLoading ? '' : exportPdf(0)"
      v-loading="pdfLoading"
      element-loading-text="正在下载"
      element-loading-background="rgba(0, 0, 0, 0)"
    >
      {{ pdfLoading ? '' : '下载报告' }}
    </div>
    <template v-for="(report, index) in reportList">
      <div class="report-container" :key="report.id" :id="`examReport${index}`">
        <div class="top">
          <img :src="require('@assets/logo/report-logo.png')" height="38px" alt="" />
          <div>
            https://enter.yuantusoft.com/home
            <img :src="require('@assets/logo/tencent-cloud-qrcode.png')" height="44px" width="44px" alt="" />
          </div>
        </div>
        <h1 v-if="firstPage" style="font-size: 34px; margin-bottom: 19px; text-align: center;">
          {{ organizationName }}-{{ postName }}个人分析报告<span v-if="isRetest === true" class="retest">重考</span>
        </h1>
        <div class="block">
          <div :style="{ opacity: firstPage ? 1 : 0, height: firstPage ? 'auto' : 0 }">
            <p class="title">一、基本信息</p>
            <h1><span>1.1</span><span>基本信息</span></h1>
            <ul class="info">
              <li v-for="(item, key, index) in report.examInfo" style="width: 50%" :key="index">{{ item.label }}：{{ item.value }}</li>
            </ul>
            <h1><span>1.2</span><span>测评得分情况</span></h1>
            <div :ref="`scoreBarChart-${report.id}`" style="height: 280px; margin: 19px 0 18px 0;"></div>
          </div>
        </div>
        <div class="block list">
          <div :style="{ opacity: secondPage ? 1 : 0, height: secondPage ? 'auto' : 0 }">
            <p class="title" style="margin-bottom: 19px">二、测评结果分析</p>
            <div :ref="`skillOverallRadarChart-${report.id}`" style="height: 384px"></div>
            <div :ref="`distributionChart-${report.id}`" style="height: 418px; margin-top: 16px;"></div>
            <el-table :data="report.userExamAbilityRate" class="yt-table">
              <el-table-column label="技能名称" prop="abilityName" :width="180" align="center" />
              <el-table-column label="掌握率" align="center">
                <template slot-scope="scope">{{ $roundFloat(scope.row.userRate, 2) }}%</template>
              </el-table-column>
              <el-table-column label="平均掌握率" align="center">
                <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
              </el-table-column>
              <el-table-column label="差异" prop="differenceRate" align="center">
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.differenceRate > 0"
                    :style="{
                      color: '#4CE47A'
                    }"
                  >
                    {{ '+' + $floatMultiply(scope.row.differenceRate, 100) + '%' }}
                  </span>
                  <span
                    v-else-if="scope.row.differenceRate < 0"
                    :style="{
                      color: '#FF6060'
                    }"
                  >
                    {{ $floatMultiply(scope.row.differenceRate, 100) + '%' }}
                  </span>
                  <span v-else>0</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div :style="{ opacity: thirdPage ? 1 : 0, height: thirdPage ? 'auto' : 0 }">
            <template v-if="report.subSkillList.length > 0">
              <p class="title" style="margin: 19px 0 0">三、详细技能分析</p>
            </template>
            <template v-for="(item, index) in report.subSkillList">
              <div :style="{ marginTop: index === 0 ? '19px' : '17px' }" class="skill-item" :key="item.id">
                <h2>3.{{ index + 1 }} {{ item.abilityName }}详细技能掌握情况</h2>
                <template v-if="item.hasOwnProperty('subAbilityRateVO')">
                  <div
                    class="radar-chart"
                    :class="[`skill-${item.subAbilityRateVO.length > 4 ? 4 : item.subAbilityRateVO.length}`]"
                    :ref="'subSkillRadarChart-' + item.id"
                  ></div>
                  <div :ref="'subSkillBarChart-' + item.id" :style="{ height: `${item.subAbilityRateVO.length * 67 + 50}px` }"></div>
                  <el-table :data="item.subAbilityRateVO" class="yt-table">
                    <el-table-column label="技能名称" prop="abilityName" :width="180" align="center" />
                    <el-table-column label="描述" prop="description" align="center" />
                    <el-table-column label="个人掌握率" width="120" align="center">
                      <template slot-scope="scope">{{ $roundFloat(scope.row.userRate, 2) }}%</template>
                    </el-table-column>
                    <el-table-column label="平均掌握率" align="center">
                      <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
                    </el-table-column>
                  </el-table>
                </template>
                <h4 class="description" v-if="item.description !== null && item.description !== ''">技能描述:{{ item.description }}</h4>
              </div>
            </template>
          </div>
          <div :style="{ opacity: fourthPage ? 1 : 0, height: fourthPage ? 'auto' : 0 }">
            <template v-if="report.departmentMatchVOS.length > 0">
              <div class="title" style="margin-top: 19px">
                {{ report.subSkillList.length > 0 ? '四' : '三' }}、岗位匹配分析
                <div v-for="item in match" class="match">
                  <div class="circle" :style="{ backgroundColor: item.color }"></div>
                  <p class="match-text">{{ item.text }}</p>
                </div>
              </div>
            </template>
            <template v-for="(item, index) in report.departmentMatchVOS">
              <div :style="{ marginTop: index === 0 ? '19px' : '80px' }" class="skill-item">
                <h2 style="margin-bottom: 17px; color: #000000 ">
                  {{ report.subSkillList.length > 0 ? 4 : 3 }}.{{ index + 1 }} {{ item.departmentName }}岗位匹配情况分析
                </h2>
                <template>
                  <el-table :data="item.departmentRateEvaluationVOS" :span-method="arraySpanMethod" class="yt-table">
                    <el-table-column label="技能" prop="mainSkillName" align="center" />
                    <el-table-column label="子技能" prop="subSkillName" />
                    <el-table-column label="权重" prop="weight" width="62px" align="right" />
                    <el-table-column :label="item.departmentName" width="170px" align="center">
                      <template slot-scope="scope">
                        <span
                          v-if="scope.row.rateName"
                          class="match-rate"
                          :style="{
                            color: scope.row.qualified === true ? '#4CE47A' : '#FF5050',
                            backgroundColor: scope.row.qualified === true ? '#EFFFF4' : '#FFEBEB'
                          }"
                        >
                          {{ scope.row.rateName }}
                        </span>
                        <span v-else>未配置</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </div>
            </template>
          </div>
        </div>
        <div class="bottom-text" style="margin-top: 15px;">源图数字人才管理平台</div>
        <div class="bottom-text">- 精准测评 精准培训 精准识别 精准任用 -</div>
      </div>
    </template>
    <canvas id="uploadCanvas" width="32" height="32" style="display: none"></canvas>
  </el-dialog>
</template>

<script>
import barLineChart from './reportCharts/barLineChart'
import radarChart from './reportCharts/radarChart'
import barHorizontalChart from './reportCharts/barHorizontalChart'
import barDoubleChart from './reportCharts/barDoubleChart'
import pieChart from './reportCharts/pieLoopChart'
import barStackChart from './reportCharts/barStackChart'
import { pdfD2 } from '@util/pdfDown2'
import enterEvaluateReportApi from '@api/enterEvaluateReport'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default {
  name: 'PersonalReport',
  data() {
    return {
      pdfLoading: false,
      visible: false,
      formData: {},
      reportList: [],
      organizationName: '',
      postName: '',
      isRetest: false,
      examInfo: {
        userName: {
          label: '姓名',
          value: ''
        },
        jobNumber: {
          label: '工号',
          value: ''
        },
        score: {
          label: '测评分数',
          value: ''
        },
        ranking: {
          label: '测评排名',
          value: ''
        },
        avgScore: {
          label: '测评平均分',
          value: ''
        },
        submitTime: {
          label: '测评时间',
          value: ''
        },
        duration: {
          label: '测评用时',
          value: ''
        },
        examName: {
          label: '测评主题',
          value: ''
        }
      }, //基本信息
      match: [
        {
          text: '不符合',
          color: '#FF5050'
        },
        {
          text: '符合',
          color: '#4CE47A'
        }
      ],
      firstPage: true,
      secondPage: true,
      thirdPage: true,
      fourthPage: true,
      loading: false
    }
  },
  computed: {
    groupNum() {
      //获取分组数据
      return new Set(this.departmentRateEvaluationVOS.map(item => item.mainSkillName))
    }
  },
  methods: {
    open(payload) {
      this.loading = true
      this.visible = true
      this.formData = payload
      this.$nextTick(() => {
        this.getPersonalReport()
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.reportList = []
    },
    getPersonalReport() {
      enterEvaluateReportApi
        .getPersonalReport(this.formData)
        .then(res => {
          this.reportList = res.res.map(report => {
            let res = {
              id: this.$generateUUID(),
              examInfo: this.$deepCopy(this.examInfo),
              scoreBarChart: null,
              skillRadarChart: null,
              distributionChart: null,
              pageLoading: false,
              ...report
            }
            //基本信息
            Object.keys(res.examInfo).forEach(key => {
              res.examInfo[key].value = report.userExamInfoVO[key]
            })
            res.departmentMatchVOS.forEach(item => {
              let list = item.departmentRateEvaluationVOS
              let tmp = list.map(data => data.mainSkillName) //tmp每条目标数据mainSkillName
              item.departmentRateEvaluationVOS = list.map(row => {
                let len = list.filter(data => data.mainSkillName === row.mainSkillName).length
                let index = tmp.indexOf(row.mainSkillName)
                return { ...row, len: len, startIndex: index }
              })
            })
            this.organizationName = res.userExamInfoVO.organizationName
            this.postName = res.userExamInfoVO.postName
            this.isRetest = res.userExamInfoVO.isRetest
            res.userExamAbilityRate = report.userExamAbilityRate.map(item => {
              return {
                id: this.$generateUUID(),
                subSkillRadarChart: null,
                subSkillBarChart: null,
                ...item
              }
            })
            res.subSkillList = report.userExamAbilityRate
              .filter(item => {
                return item.hasOwnProperty('subAbilityRateVO')
              })
              .map(item => {
                return {
                  id: this.$generateUUID(),
                  subSkillRadarChart: null,
                  subSkillBarChart: null,
                  ...item
                }
              })
            return res
          })
          this.$nextTick(() => {
            this.reportList = this.reportList.map(report => {
              //题型
              report.scoreBarChart = this.initScoreBarChart(report)
              //技能总体
              if (report.userExamAbilityRate.length > 0) {
                report.skillRadarChart = this.initSkillOverallRadarChart(report)
              }
              //个人位于整体情况
              report.distributionChart = this.initDistributionChart(report)
              //详细技能
              report.subSkillList = report.subSkillList.map(item => {
                if (item.hasOwnProperty('subAbilityRateVO')) {
                  item.subSkillRadarChart = this.initSkillRadarChart(item)
                  item.subSkillBarChart = this.initSkillBarChart(item)
                }
                return item
              })
              return report
            })
          })
        })
        .catch(() => {
          this.visible = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    initScoreBarChart(report) {
      //题型得分柱状图
      let barOption = JSON.parse(JSON.stringify(barStackChart.option))
      barOption.xAxis.data = report.sectionScoreInfoVOList.map(item => {
        return item.sectionName
      })
      barOption.series[0].data = report.sectionScoreInfoVOList.map(item => {
        return item.score === 0 ? null : item.score
      })
      barOption.series[1].data = report.sectionScoreInfoVOList.map(item => {
        return item.lostScore === 0 ? null : item.lostScore
      })
      let scoreBarChart = this.$echarts.init(this.$refs[`scoreBarChart-${report.id}`][0])
      scoreBarChart.setOption(barOption)
      return scoreBarChart
    },
    initDistributionChart(report) {
      //个人位于整体情况
      let lineOption = JSON.parse(JSON.stringify(barLineChart.option))
      lineOption.title.text = '- 个人测评位于整体情况 -'
      lineOption.legend.data = ['比率']
      lineOption.legend.textStyle.padding = [0, 0, 0, 5]
      lineOption.grid.left = 92
      lineOption.xAxis.data = report.statisticsVOList.map(item => {
        return item.space
      })
      lineOption.xAxis.interval = 0
      lineOption.yAxis[0].max = 1
      lineOption.yAxis[0].axisLabel.formatter = (value, index) => {
        return this.$roundFloat(value, 0) + '%'
      }
      lineOption.yAxis = lineOption.yAxis.slice(0, 1)
      lineOption.series[0] = JSON.parse(JSON.stringify(lineOption.series[1]))
      lineOption.series[0].data = report.statisticsVOList.map(item => {
        return item.frequency
      })
      lineOption.series[0].yAxisIndex = 0
      lineOption.series[0].symbol = 'none'
      lineOption.series[1].name = '个人'
      lineOption.series[1].type = 'scatter'
      delete lineOption.series[1].yAxisIndex
      lineOption.series[1].data = [[report.personalStatisticsVO.space, report.personalStatisticsVO.frequency]]
      lineOption.series[1].color = '#FFC117'
      lineOption.series[1].symbolSize = 11
      lineOption.series[1].label = {
        show: true,
        fontSize: 16,
        position: 'top',
        formatter: ({ data }) => {
          return `分数: ${report.userExamInfoVO.userScore}分      比率: ${this.$roundFloat(data[1], 0)}%`
        }
      }
      let distributionChart = this.$echarts.init(this.$refs[`distributionChart-${report.id}`][0])
      distributionChart.setOption(lineOption)
      return distributionChart
    },
    initSkillOverallRadarChart(report) {
      //技能整体掌握雷达图
      let radarOption = JSON.parse(JSON.stringify(radarChart.option))
      radarOption.legend.data.push({ icon: 'circle', name: '平均掌握率' })
      radarOption.legend.orient = 'vertical'
      radarOption.legend.itemGap = 26
      radarOption.legend.right = 61
      if (report.userExamAbilityRate.length < 3) {
        radarOption.radar[0].startAngle = 180
      }
      if (report.userExamAbilityRate.length === 1) {
        radarOption.radar[0].axisLine.show = true
      }
      radarOption.radar[0].indicator = report.userExamAbilityRate.map(item => {
        return { name: item.abilityName, max: 1 }
      })
      radarOption.series[0].data[0].value = report.userExamAbilityRate.map(item => {
        return item.userRate
      })
      radarOption.series[0].data.push({
        name: '平均掌握率',
        value: report.userExamAbilityRate.map(item => {
          return item.averageRate
        }),
        itemStyle: {
          color: '#F16E57', //改变折线点的颜色
          lineStyle: {
            color: '#F16E57' //改变折线颜色
          }
        }
      })
      let skillOverallRadarChart = this.$echarts.init(this.$refs[`skillOverallRadarChart-${report.id}`][0])
      skillOverallRadarChart.setOption(radarOption)
      return skillOverallRadarChart
    },
    initSkillRadarChart(skill) {
      //技能详细掌握雷达图
      let radarOption = JSON.parse(JSON.stringify(radarChart.option))
      if (skill.subAbilityRateVO.length < 3) {
        radarOption.radar[0].startAngle = 180
      }
      if (skill.subAbilityRateVO.length === 1) {
        radarOption.radar[0].axisLine.show = true
      }
      radarOption.legend.data.push({ icon: 'circle', name: '平均掌握率' })
      radarOption.radar[0].indicator = skill.subAbilityRateVO.map(item => {
        return { name: item.abilityName, max: 1 }
      })
      radarOption.series[0].data[0].value = skill.subAbilityRateVO.map(item => {
        return item.userRate
      })
      radarOption.series[0].data.push({
        name: '平均掌握率',
        value: skill.subAbilityRateVO.map(item => {
          return item.averageRate
        }),
        itemStyle: {
          color: '#F16E57', //改变折线点的颜色
          lineStyle: {
            color: '#F16E57' //改变折线颜色
          }
        }
      })
      let subSkillRadarChart = this.$echarts.init(this.$refs[`subSkillRadarChart-${skill.id}`][0])
      subSkillRadarChart.setOption(radarOption)
      return subSkillRadarChart
    },
    initSkillBarChart(skill) {
      //技能详细掌握柱状图
      let barOption = JSON.parse(JSON.stringify(barDoubleChart.option))
      barOption.yAxis.data = skill.subAbilityRateVO.map(item => {
        return item.abilityName
      })
      barOption.grid.bottom = 16
      barOption.yAxis.axisLabel.interval = 0
      barOption.series[0].name = '掌握率'
      barOption.series[0].label.fontSize = '16px'
      barOption.series[0].data = skill.subAbilityRateVO.map(item => {
        return this.$roundFloat(item.userRate, 2)
      })
      barOption.series.push({
        name: '平均掌握率',
        type: 'bar',
        data: [],
        label: {
          show: true,
          formatter: '{c}%',
          position: 'right',
          fontSize: '16px'
        },
        color: '#E8EAEE',
        barWidth: 16,
        barGap: 0
      })
      barOption.series[1].data = skill.subAbilityRateVO.map(item => {
        return this.$roundFloat(item.averageRate, 2)
      })
      let subSkillBarChart = this.$echarts.init(this.$refs[`subSkillBarChart-${skill.id}`][0])
      subSkillBarChart.setOption(barOption)
      return subSkillBarChart
    },
    exportPdf(index) {
      this.pdfLoading = true
      this.secondPage = false
      this.thirdPage = false
      this.fourthPage = false
      this.$nextTick(() => {
        let pdf = new JsPDF({ orientation: 'p', unit: 'px' })
        pdf.deletePage(1)
        html2canvas(document.getElementById(`examReport${index}`), {
          scale: 1,
          useCORS: true // 跨域请求
        }).then(canvas => {
          let contentWidth = canvas.width
          let contentHeight = canvas.height
          //一页pdf显示html页面生成的canvas高度;
          let pageData = canvas.toDataURL('image/jpeg', 1.0)
          //可动态生成
          let height = (595 / contentWidth) * contentHeight
          pdf.addPage([595, height > 841 ? height : 841])
          pdf.addImage(pageData, 'JPEG', 0, 0, 595, (595 / contentWidth) * contentHeight)
          this.firstPage = false
          this.secondPage = true
          this.$nextTick(() => {
            html2canvas(document.getElementById(`examReport${index}`), {
              scale: 1,
              useCORS: true // 跨域请求
            }).then(canvas => {
              let contentWidth = canvas.width
              let contentHeight = canvas.height
              //一页pdf显示html页面生成的canvas高度;
              let pageData = canvas.toDataURL('image/jpeg', 1.0)
              let height = (595 / contentWidth) * contentHeight
              pdf.addPage([595, height > 841 ? height : 841])
              //可动态生成
              pdf.addImage(pageData, 'JPEG', 0, 0, 595, (595 / contentWidth) * contentHeight)
              this.secondPage = false
              this.thirdPage = true
              this.$nextTick(() => {
                html2canvas(document.getElementById(`examReport${index}`), {
                  scale: 2,
                  dpi: 450,
                  useCORS: true // 跨域请求
                }).then(canvas => {
                  let contentWidth = canvas.width
                  let contentHeight = canvas.height
                  //一页pdf显示html页面生成的canvas高度;
                  let pageData = canvas.toDataURL('image/jpeg', 1.0)
                  if (this.reportList[index].subSkillList.length > 0) {
                    let height = (595 / contentWidth) * contentHeight
                    pdf.addPage([595, height > 841 ? height : 841])
                    //可动态生成
                    pdf.addImage(pageData, 'JPEG', 0, 0, 595, (595 / contentWidth) * contentHeight)
                  }
                  this.thirdPage = false
                  this.fourthPage = true
                  this.pdfLoading = false
                  this.addPage(pdf, index)
                })
              })
            })
          })
        })
      })
      // this.makePdf(0)
    },
    addPage(pdf, index) {
      this.$nextTick(() => {
        html2canvas(document.getElementById(`examReport${index}`), {
          scale: 1,
          useCORS: true // 跨域请求
        }).then(canvas => {
          let contentWidth = canvas.width
          let contentHeight = canvas.height
          //一页pdf显示html页面生成的canvas高度;
          let pageData = canvas.toDataURL('image/jpeg', 1.0)
          if (this.reportList[index].departmentMatchVOS.length > 0) {
            let height = (595 / contentWidth) * contentHeight
            pdf.addPage([595, height > 841 ? height : 841])
            //可动态生成
            pdf.addImage(pageData, 'JPEG', 0, 0, 595, height)
          }
          if (index === this.reportList.length - 1) {
            pdf.save(
              `${this.reportList[index].examInfo.userName.value}${
                this.reportList[index].examInfo.jobNumber.value === undefined ? '' : this.reportList[index].examInfo.jobNumber.value
              }.pdf`
            )
            this.firstPage = true
            this.secondPage = true
            this.thirdPage = true
            this.fourthPage = true
          } else {
            if (index === 0) {
              this.fourthPage = false
            }
            pdf.save(
              `${this.reportList[index].examInfo.userName.value}${
                this.reportList[index].examInfo.jobNumber.value === undefined ? '' : this.reportList[index].examInfo.jobNumber.value
              }.pdf`
            )
            index++
            this.firstPage = true
            this.exportPdf(index)
          }
        })
      })
    },
    backTop() {
      document.querySelector('.personal-report-dialog').scrollTop = 0
    },
    //table合并标签
    nameGroup(mainSkillName) {
      // 获取相同名称的数量
      return this.departmentRateEvaluationVOS.filter(item => item.mainSkillName === mainSkillName).length
    },
    nameLen(mainSkillName) {
      //根据名称获取名称第一个数据在全部数据中的偏移位置
      const tmp = Array.from(this.groupNum) //tmp每条目标数据 mainSkillName
      const index = tmp.indexOf(mainSkillName) //该名称在全名称中的偏移位置 index对应名称的下标
      let len = 0 //对应每个名称的行数
      for (let i = 0; i < index; i++) {
        len += this.nameGroup(tmp[i])
      }
      return len
    },
    arraySpanMethod(data) {
      //对于表格数据进行分组合并操作，UI组件回调函数
      const { row, rowIndex, columnIndex } = data
      if (columnIndex === 0) {
        //名称列 合并展示区
        if (rowIndex !== row.startIndex) {
          row.mainSkillName = ''
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/reportVer1';
@import '~@/theme/pdfLoading.less';
::v-deep .el-dialog {
  width: 1240px;
  border-radius: 0;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.print-button {
  position: fixed;
  top: 500px;
  right: 100px;
  width: 140px;
  padding: 25px 40px;
  height: 140px;
  text-align: center;
  color: #ffffff;
  font-size: 30px;
  background: #448bff;
  box-shadow: 0 3px 6px rgba(60, 100, 209, 0.5);
  border-radius: 100px;
  cursor: pointer;
}
.back-top {
  background-color: #ff6060;
  box-shadow: 0 3px 6px rgba(255, 159, 159, 0.5);
  top: 330px;
}
.yt-table {
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  ::v-deep.el-table__header {
    thead {
      th {
        background-color: #d9d9d9 !important;
      }
    }
  }
}
.match {
  justify-content: flex-end;
  display: inline-flex;
  font-weight: normal;
  margin-top: 7px;
  margin-right: 30px;
  float: right;
  .circle {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    margin-top: 9px;
    border-radius: 50%;
  }
  .match-text {
    font-size: 16px;
  }
}
.match-rate {
  border-radius: 12px;
  padding: 0 17px;
}
.retest {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 5px 20px;
  padding-left: 5px;
  width: 62px;
  height: 30px;
  background: url(../../../../assets/形状结合.png);
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}
.report-container {
  padding-bottom: 16px;
  .top {
    margin-bottom: 16px;
  }
  .block {
    margin-bottom: 0;
    .title {
      font-size: 21px;
      margin-bottom: 19px;
    }
    h1 {
      margin-top: 18px;
      span {
        font-size: 18px;
      }
      span:first-child {
        width: 38px;
      }
    }
    .info {
      li {
        font-size: 16px;
        margin-top: 16px;
      }
    }
    .skill-item {
      h2 {
        font-size: 18px;
        color: #000;
      }
    }
    h1:first-of-type {
      margin-bottom: 1px;
    }
    &.list {
      ::v-deep.el-table__header thead th:first-child .cell {
        display: flex;
        justify-content: center;
        /*padding-left: 92px;*/
      }
    }
  }
  /deep/ .yt-table {
    .cell {
      font-size: 16px !important;
    }
  }
}
.bottom-text {
  .flexStyle();
  width: 100%;
  font-size: 16px;
  color: #999999;
}
.el-table {
  /deep/ th.el-table__cell:first-child > .cell {
    /*padding-left: 72px;*/
  }
}
.description {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 400;
  margin: 20px 30px 0 30px;
}
</style>
